<template>
    <div class="view">
      <div id="text">
        <h1>Welcome to <span style="color: #5E6AD2">Data</span>fy</h1>
        <p>
          Helping thousands of active users stucture their data in way <br>
          that doesn’t hurt their brain
        </p>
      </div>
      <div id="image">
        <img :src="img" class="image" alt="database"/>
      </div>

      <div id="balk" class="balkDiv">
        <img :src="balk50" alt="attribute" class="balk"/>
        <img :src="balk" alt="attribute" class="balk"/>
        <img :src="balk" alt="attribute" class="balk"/>
      </div>

      <div id="buttonclass">
        <button v-on:click="$router.push('secondpage')">Get Started</button>
      </div>
      <p>Already a user? <a href="api/auth">login here!</a></p>

    </div>
</template>

<script>
import image from '/static/database.svg'
import balk from '/static/balken/balk.svg'
import balk50 from '/static/balken/balk50.svg'

export default {
  name: 'HelloWorld',
  data(){
    return {
      img: image,
      balk: balk,
      balk50: balk50
    }
  },
}


</script>

<style scoped>



h1 {
  font-weight: 400;
  font-size: 3.5vw;
  margin-top: 3vw;
  color: white;
  margin-bottom: 0;
}

button{
  font-size: 1.5em;
  padding:0.5vw 7vw;
  border-radius: 0.2em;
  background-color: #5E6AD2;
  display: inline-block;
  text-align: center;
  margin: 2em 2px;
  color: white;
  cursor: pointer;
  box-shadow: 0 12px 20px 0 rgba(0,0,0,0.2), 0 12px 30px 0 rgba(0,0,0,0.19);
}

p {
  margin-top: 0;
  color:white;
  opacity: 0.35;
  font-size: 0.8vw;
  margin-bottom: 5em;
}

.image {
  width: 12vw;
  margin-bottom: 5vw;
}

</style>
