<template>
  <div class="unniversal-class-flex">
    <side-bar></side-bar>
   <div class="forms pb-3 dark .bg-white rounded-3">
        <h6 class="px-4 pt-3 color2 fw500">Account Settings</h6>
      <hr><div class="px-4">
       <label for="Name">Account Name</label>
       <br>
        <input type="text" placeholder="MoloAIO">
       <br><br>
       <label for="Name">Website URL</label>
       <br><input type="text" placeholder="https://moloaio.com">
       <br><br><label for="Name">Domain</label>
       <br><input type="text" placeholder="moloaio.reformdash.com">
       <br><br>
       </div>
       </div>
  </div>
       
</template>

<script>
import sideBar from '@/components/mainPageComponents/sideBar.vue'

export default {
  components: { sideBar },
  data(){
    return {
        
    }
  },
}


</script>

<style scoped>
.dark .bg-white{
  background-color: #181818!important;
}

</style>
