<template style="max-height: 100vh">
  <div id="hello">

    <div id="text">
      
      
      
      <h2>Tell us something about you!</h2>
      <p>
        Of course you can sign up using a fake name but<br> we would love for our transparency to go both <br>ways
      </p>
      
    </div>
    <v-snackbar v-model="show" top color="red" rounded="pill">
      <span>Please fill in all the forms correctly</span>
    </v-snackbar>

    
      <v-form ref="form"  v-model="valid" class="input">
      <v-row align="center">
        <v-col md="6">
          <v-text-field  
            v-model="nameFull"  
            :rules="nameRules" 
            :counter="24"
            placeholder="Full name" 
            class="v-text-field"
            required 
            solo-inverted 
             background-color="white"
            
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-select 
            v-model="role" 
            :items="items" 
            label="Role" 
            solo-inverted
            :rules="inputRules"
            background-color="white"
          ></v-select>
        </v-col>
        <v-col md="6">
          <v-text-field 
          v-model="email" 
          :rules="emailRules" 
          placeholder="email adress" 
          class="v-text-field" 
          required
          solo-inverted
          background-color="white"
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="date"
                  label="Birthday date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="inputRules"
                  solo-inverted
                   background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1910-01-01"
                @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      </v-form>


    <div id="balk" class="balkDiv">
      <img :src="balk100" alt="attribute" class="balk"/>
      <img :src="balk100" alt="attribute" class="balk"/>
      <img :src="balk" alt="attribute" class="balk"/>
    </div>

    <div id="buttonclass">
      <button v-on:click="click">Continue your journey</button>
    </div>
  </div>
</template>

<script>
// import image from '/static/database.svg'
import balk from '/static/balken/balk.svg'
import balk100 from '/static/balken/balk100.svg'

export default {
  setup: {

  },
  data: () => ({
      valid: true,
      balk: balk,
      balk100: balk100,
      items: ['Student', 'Teacher', 'Individual'],
      role: '',
      menu: null,
      date: null,
      activePicker: null,
      info: [{name: '', birthday: '', role: '', email: ''}],
      nameFull: '',
      nameRules: [
        value => !!value || 'Name is required',
        value => value.length <= 24 || 'Name must be less than 10 characters'
        ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        //eslint-disable-next-line
        v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
      ],
      inputRules: [
        v => !!v || 'Field must be filled'
      ],
      show : false,
      
  }),
  methods: {
    click: function (){
      if(this.valid){
        this.submit()
        this.$router.push('fourthpage')
      } else{
        this.$refs.form.validate()
        this.show = true
      }
    },
    async createUser(user){
      let options = {
        method: "POST",
        headers: {
         'Content-Type': 'application/json'
        },
        body: user
      }
      let r = await fetch(`/api/user`, options)
      switch(r.status){
      
        case 401: {
          return this.$router.push('Startpage');
        }
        case 403: {
          return window.location.href='/api/auth';
        }
      }
      
    },
    save (date) {
      this.$refs.menu.save(date)
    },

    submit: function (){
      this.info = { nameFull: this.nameFull, birthday: this.date, role: this.role, email: this.email}
      localStorage.setItem("info", JSON.stringify(this.info))
      this.$store.commit('save')
      this.createUser(JSON.stringify(this.info))
    }
  },
}


</script>

<style scoped >
h2 {
  font-size: 2em;
  margin-top: 4%;
  color: white;
  margin-bottom: 0;
}

button{
  font-size: 1.5em;
  padding:0.5vw 7vw;
  border-radius: 0.2em;
  background-color: #5E6AD2;
  display: inline-block;
  text-align: center;
  margin: 2em 2px;
  color: white;
  cursor: pointer;
  box-shadow: 0 12px 20px 0 rgba(0,0,0,0.2), 0 12px 30px 0 rgba(0,0,0,0.19);
}

p {
  color: #454e5c;
  font-size: 1.5em;
  margin-bottom: 80px;
}

.row{
  flex-direction: column;
}


.input{
  padding: 0 500px 0 500px;
  height: 400px;
  color: white;

  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between; */
}

</style>
