<template>
    <div class="unniversal-class-flex">
      <div class="sidebar">
        <side-bar></side-bar>
      </div>

      <div class="chart-collection">
        <div class="backBtn">
          <v-btn
            color="green darken-2"
            fab
            dark
            style="margin: 100px 20px 20px 40px"
            v-on:click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div class="chart" v-if="renderComponent">
          <h1>{{ filteredPost.text }}</h1>
          <component :is="componentName" :chartData="this.filteredPost.data" :options="options" :label="labels" :charColor="colorGraph"/>
        </div>
        <div class="addBtn">
          <v-btn
            color="indigo"
            fab
            dark
            style="margin: 100px 40px 20px 20px"
            v-on:click="showInputData = true"
          >
          
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>




      <div>
           <v-dialog
             v-model="showInputData"
             width="600"
             :retain-focus="false"
            >
            <v-card height="80vh">
                  <v-card-title class="text-center">Add Data</v-card-title>
                  <v-expansion-panels
                    v-model="panelIndex"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Date fields
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p style="text-align: left">What is the label of the line</p>
                            <v-text-field
                              v-model="labels"
                              
                            ></v-text-field>
                         <!-- <v-select
                          v-model="dateTypes"
                          outlined
                          :items="itemsDate"
                          item-text="displayName"
                          return-object
                          style="width: 15vw"
                         ></v-select> -->
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="rangeDate"
                              label="Range Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="rangeDate"
                            @change="save"
                            range
                          ></v-date-picker>
                        </v-menu>
                        <div v-if="arrDates">
                        <v-row  
                          v-for="(listedObject, key, index) in arrDates"
                          v-bind:item="listedObject"
                          v-bind:index="index"
                          v-bind:key="key"
                          no-gutters
                          style="height=150px;"
                        >
                        <v-col>
                          {{ listedObject.date +':'}}
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="listedObject.value"
                            style="width: 10vw;"
                            outlined
                            type="number"
                          ></v-text-field>
                        </v-col>
                        </v-row>
                        </div>
                        <v-btn
                          color="primary"
                          v-on:click="pushDataDate()"
                        >
                          Apply
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="displayName!='Line Chart'">
                      <v-expansion-panel-header>
                        Custom fields
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p style="text-align: left">What is the label of the line</p>
                            <v-text-field
                              v-model="labels"
                              
                            ></v-text-field>
                        <p style="text-align: left">How many rows you want to show?</p>
                        <v-text-field 
                          v-model="customNumberRows" 
                          type="Number"
                          ></v-text-field>
                        <v-row
                          v-for="(item, key, index) in customNumberRowsArray"
                          v-bind:item="item"
                          v-bind:index="index"
                          v-bind:key="key"
                          no-gutters
                          style="height=150px;"
                        >
                        <v-col>
                          <v-text-field
                            v-model="item.date"
                            style="width: 10vw;"
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col >
                          <v-text-field
                            v-model="item.value"
                            style="width: 10vw;"
                            outlined
                            type="number"
                          ></v-text-field>
                        </v-col>
                        </v-row>
                        <v-btn
                          color="primary"
                          v-on:click="pushDataCustom()"
                        >
                          Apply
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                  </v-expansion-panels>
                  <div v-if="displayName!='Pie Chart'">
                    <p class="expansion-text">Chose color of the graph:</p>
                    <div class="color-picker-div">
                      <v-color-picker
                        v-model="colorGraph"
                        dot-size="25"
                        mode="hexa"
                        swatches-max-height="200"
                      ></v-color-picker>
                    </div>
                    <v-btn
                      color="primary"
                      v-on:click="addColor()"
                    >
                      Apply
                    </v-btn>
                  </div>
              </v-card>
          </v-dialog>

      </div>

    </div>
</template>

<script>
import PostService from '../PostService'
import sideBar from './mainPageComponents/sideBar.vue'
import moment from 'moment'

export default {
    components: { sideBar },
    data(){
        return {
            posts: [],
            filteredPost: [],
            labels: '',
            options: {
              responsive: true,
              maintainAspectRatio: false
            },
            componentName: '',
            showInputData: false,
            numberValue: 0,
            panelIndex: '',
            dateTypes: '',
            itemsDate: [],
            dateText: '',
            rangeDate: [],
            menu: false,
            arrDates: [],
            arrDatesFlat: [],
            dataFromFields: [],
            sendArrDates: [],
            lengthArray: 0,
            renderComponent: true,
            dataFields: 1,
            customNumberRows: 0,
            customNumberRowsArray: [],
            displayName: '',
            colorGraph: '',
        }
    },
    async created() {
    this.dataSet = [{date: '2020-01-12', total: 543}, {date: '2020-01-13', total: 1567}, {date: '2020-01-14', total: 134}, {date: '2020-01-15', total: 1034}]
    try {
      this.posts = await PostService.getPosts()
      this.posts.forEach(element => {
        if(element._id === this.$route.params.id){
          this.filteredPost = element
        }
      })
    } catch(err){
      this.error = err.message
    }
      if(this.filteredPost.kind.fileName.length > 0){
        this.componentName = this.filteredPost.kind.fileName;
        this.displayName = this.filteredPost.kind.displayName;
        this.$options.components[this.componentName] = () => import('./chartComponents/' + this.componentName + '.vue');
      }
      // window.addEventListener('keydown', (e) => {
      //   if(e.key == 'Tab' && this.tabDetection === 1){
      //     this.arrCustom = this.arrCustom.push(0)
      //     console.log(this.arrCustom)
      //   }
      // })

      if(this.filteredPost.kindOfData === "custom"){
        this.customNumberRowsArray = this.filteredPost.data
        if(this.customNumberRowsArray){
          this.customNumberRows = this.customNumberRowsArray.length
        }
      } else if(this.filteredPost.kindOfData === "date"){
        this.arrDates = this.filteredPost.data
        if(this.arrDates != ''){
          this.rangeDate = this.filteredPost.data[0].date + "," + this.filteredPost.data[this.filteredPost.data.length - 1].date
        }
      }
      
      this.colorGraph = this.filteredPost.color
      
      },
      watch: {
        customNumberRows: function(){
          const filledArray = Array.from(Array(parseInt(this.customNumberRows)), () => {
              return { date: '', value: ''};
          });
          if(this.customNumberRowsArray.length < this.customNumberRows){
            this.customNumberRowsArray.push({date: '', value: ''})
          }
          let numberCount = 0
          filledArray.forEach((item) => {
            item.date = this.customNumberRowsArray[numberCount].date
            item.value = this.customNumberRowsArray[numberCount].value
            numberCount++
          })
          this.customNumberRowsArray = filledArray 
        },
      },

      methods: {
        save (rangeDate) {
          this.$refs.menu.save(rangeDate)
          const datesArray = this.getDates(rangeDate[0], rangeDate[1])
          const filledArray = Array.from(Array(this.lengthArray), () => {
              return { date: 0, value: ''};
          });
          let teller = 0
          datesArray.forEach((element) => {
            filledArray[teller].date = element
            teller++
          })
          this.arrDates = filledArray
        },

        getDates(startDate, endDate) {
            var dateArray = [];
            var currentDate = moment(startDate);
            var stopDate = moment(endDate);
            while (currentDate <= stopDate) {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                currentDate = moment(currentDate).add(1, 'days');
            }
            this.lengthArray = dateArray.length
            return dateArray;
        },

        pushDataDate(){  
          PostService.editPost(this.$route.params.id, this.filteredPost.text, this.filteredPost.kind ,this.arrDates, "date", this.colorGraph)
          this.filteredPost.data = this.arrDates
          this.showInputData = false
          this.forceRerender()
        },

        pushDataCustom(){  
          PostService.editPost(this.$route.params.id, this.filteredPost.text, this.filteredPost.kind ,this.customNumberRowsArray, "custom", this.colorGraph)
          this.filteredPost.data = this.customNumberRowsArray
          this.showInputData = false
          this.forceRerender()
        },

        forceRerender(){
          this.renderComponent = false
          this.$nextTick(() => {
            this.renderComponent = true
          })
        },

        addColor(){
          this.showInputData = false
          PostService.editPost(this.$route.params.id, this.filteredPost.text, this.filteredPost.kind ,this.customNumberRowsArray, "custom", this.colorGraph)
          this.forceRerender()
        }

      },
    }
  
</script>

<style scoped>
.btn-add-data{
  margin: 20px;
}
.chart-collection{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: 5vw;
}
.chart{
  width: 60vw;
}
h1 {
  margin-bottom: 30px;
}
.color-picker {
  text-align: center;
}

.expansion-text {
  margin-top: 20px;
}
.color-picker-div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
</style>