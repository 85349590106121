import axios from "axios";

const url = '/api/posts/';

class PostService {
    //Get Posts
    static getPosts() {
        // eslint-disable-next-line
        return new Promise(async (resolve, reject) => {
            try{
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(post => ({
                        ...post, 
                        createdAt: new Date(post.createdAt)
                    }))
                );
            } catch(err){
                reject(err);
            }
        });
    }
    

    // Create Post
    static insertPost(text, kind, data, color){
        return axios.post(url, {
            text,
            kind,
            data,
            color
        });
    }

    // Delete Post
    static deletePost(id) {
        return axios.delete(`${url}${id}`);
    }

    static editPost(id, text, kind, data, kindOfData, color) {
        return axios.put(`${url}${id}`, {
            text,
            kind,
            data,
            kindOfData,
            color
        })
    }

    static addColor(id, color) {
        return axios.put(`${url}${id}`, {
            color
        })
    }

}

export default PostService;