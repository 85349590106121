<template>
  <div class="sidebar">
      <v-navigation-drawer
        permanent
        height="100vh"
        color="#27282B"

      >
      <v-container class="container-col">
      <div class="img-col">
          <img src="" class="image-top-div">
      </div>
      <div class="text-col">
          <h3>Datafy</h3>
      </div>
      </v-container>
        <v-list
         nav
        class="max-v-list-size"
        >
        <v-divider></v-divider>
        <v-list-item-group
          color="#5E6AD2"
          mandatory
        >
        <h2>Home</h2>
          <v-list-item 
            :to="overviewLink"
            link
          >
            <v-list-item-icon >
              <v-img :src="overview"></v-img>
            </v-list-item-icon>
              <v-list-item-title class="text-list-items">Overview</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          
          <!-- <h2>Manage</h2>
          
          <v-list-item 
            :to="usersLink"
            link
          >
            <v-list-item-icon>
              <v-img :src="user"></v-img>
            </v-list-item-icon>
            <v-list-item-title class="text-list-items">Users</v-list-item-title>
          </v-list-item>

          <v-list-item 
            :to="analyticsLink"
            link
          >
            <v-list-item-icon>
              <v-img :src="analytics"></v-img>
            </v-list-item-icon>
            <v-list-item-title class="text-list-items">Analytics</v-list-item-title>
          </v-list-item>

          <v-list-item 
            :to="storageLink"
            link
          >
            <v-list-item-icon>
              <v-img :src="storage"></v-img>
            </v-list-item-icon>
            <v-list-item-title class="text-list-items">Storage</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider> -->

          <!-- <h2>Maintance</h2> -->


          <!-- <v-list-item 
            :to="settingsLink"
            link
          >
            <v-list-item-icon>
              <v-img :src="settings"></v-img>
            </v-list-item-icon>
            <v-list-item-title class="text-list-items">Settings</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import profilePic from '/static/profilePic.svg'
import overview from '/static/icons/overview.svg'
import round from '/static/icons/round.svg'
import user from '/static/icons/user.svg'
import analytics from '/static/icons/analytics.svg'
import settings from '/static/icons/settings.svg'
import storage from '/static/icons/storage.svg'

  export default {
     data: () => ({
         profilePic: profilePic,
         overview: overview,
         round: round,
         user: user,
         analytics: analytics,
         settings: settings,
         storage: storage,
         overviewLink: '/mainpage',
         settingsLink: '/settingspage',
         usersLink: '/userpage',
         analyticsLink: '/analyticspage',
         storageLink: '/storagepage',
     })
  }
</script>

<style scoped>

h2 {
    text-align: left;
    padding: 1em 1em;
    font-size: 1em;
    color: #a8a6a0;
    font-size: 20px;
}
h3{
  font-size: 30px;
}


.text-list-items {
  color: #a8a6a0;
  font-size: 20px !important;
}


.max-v-list-height {
    max-height: 400px;
    
}

.text-title {
  font-size: 20px !important;
  font-size: 100px;
  color: white;
}
.image-top-div {
  flex-basis: 20%;
  width: 50px;
  border-radius: 50px;
}

.container-col {
  align-items: center;
  display: flex;
  justify-items: start;
}

.text-col {
  margin-left: 1em;
  font-size: 35px;
  color: white;
  font-family: Calibri;
}


</style>