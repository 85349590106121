<template>
    
    <div class="unniversal-class-flex" >
      <div class="loader">
       
        <div class="loader-inner">
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
          <div class="loader-line-wrap">
            <div class="loader-line"></div>
          </div>
        </div>
      </div>
      <div class="sidebar" >
        <side-bar></side-bar>
      </div>

      <div class="body">
       
        <div class="post-container">
          <div class="post"
            v-for="(post,index) in posts"
            v-bind:item="post"
            v-bind:index="index"
            v-bind:key="post._id"
          >
           
          <v-btn
              class="pencil-style-btn"
              icon
              color="green"
              style="float:left; top:0;"
              v-on:click="editPost(post._id, post.text, post.kind, post.data,post.kindOfData ,post.color)"
          >
            <v-icon 
            color="grey"
            dark>
                mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
              class="pencil-style-btn"
              icon
              color="red"
              style="float:right; top:0;"
              v-on:click="dialogDelete =true, postItems = [post.text, post._id]"
          >
            <v-icon dark>
                mdi-trash-can-outline
            </v-icon>
            </v-btn>
          <v-dialog
             v-model="dialogDelete"
             width="400"
             :retain-focus="false"
          >
            <v-card>
                  <v-card-title class="text-center">Delete</v-card-title>
                  <v-card-text>Weet je zeker dat je <b>{{postItems[0]}}</b> wenst te verwijderen?</v-card-text>
                  <v-card-actions >
                    <v-btn
                      color=primary
                      plain
                      @click="dialogDelete = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      plain
                      color="error"
                      @click="deletePost(postItems[1])"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
            {{ `${post.createdAt.getDate()}/${post.createdAt.getMonth()}/${post.createdAt.getFullYear()}`}}
            <h1 class="text">{{ post.text}}</h1>
            <p class="text">Type: {{ post.kind.displayName }}</p>
            <div class="btn-post">
              <v-btn
                color="normal"
                style="position:absolute; bottom:10px; right:10px"
                v-on:click="goToChart(post._id)"
              >
                See Chart
              </v-btn>
            </div>
          </div>
          <div class="post-add" v-on:click="setValue()">
            <img :src="PlusImage" alt="attribute" class="image-plus"/>

          </div>
          <v-dialog
            v-model="value"
            width="500"
          >

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                  New Chart
              </v-card-title>

              <v-card-text class="card-input-text-field">
                <h3
                  style="margin-top: 20px; font-size: 20px"
                >Title</h3>
                <v-text-field
                  v-model="title"
                  :rules="chartNameRules" 
                  label="put your title here"
                ></v-text-field>
                <h3 style="margin-top: 20px; font-size: 20px">Chart Type</h3>
                <v-select
                  v-model="selectedKind"
                  :items="items"
                  :rules="chartTypeRules" 
                  item-text="displayName"
                  return-object
                  label="Select a category"
                  style="margin-bottom: 40px"
                >
                </v-select>
              </v-card-text>
              <v-card-actions class="button-align-right">
                <v-btn v-on:click="createPost()" large>
                Create
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
            
        </div>
      </div>
    </div>
   
</template>

<script>
import sideBar from './mainPageComponents/sideBar.vue'
import PostService from '../PostService'
import PlusImage from '/static/plus.png'



// function removeLoader(){
//   setTimeout(() => {
//     document.getElementsByClassName('loader')[0].style.display = 'none'
//   }, (2000));
// }
// removeLoader();




export default {
  components: { sideBar },
  data(){
    return {
        posts: [],
        err: '',
        text: '',
        title: '',
        PlusImage,
        selectedKind: '',
        value: false,
        dialogDelete: false,
        items: [
          {
            displayName: 'Bar Chart',
            fileName: 'barChart' 
          }, 
          {
            displayName: 'Line Chart', 
            fileName: 'lineChart'
          }, 
          {
            displayName: 'Pie Chart',
            fileName: 'pieChart'
          }
        ],
        chartNameRules: [
        value => !!value || 'Name is required',
        value => value.length <= 24 || 'Name must be less than 10 characters'
        ],
        chartTypeRules: [
        v => !!v || 'Field must be filled'
        ],
        postItems: [],
        existingFileEdit: false,
        existingId: '',
        dataPost: [],
        colorPost: '',
        kindOfDataPost: '',
    }
  },
  
 async mounted() { //initates after load(mount)
      let sessionToken = this.$cookies.get("userSession");
       let querySessionToken = this.$route.query.token;

  
      if(querySessionToken){
        this.$cookies.set("userSession", querySessionToken, "1730s")
        sessionToken=querySessionToken //Expires 30s before jwt so we can revalidate
      }
     
      const token = sessionToken ? sessionToken: querySessionToken
      if(token == null){
        return this.$router.push('Startpage');
      }
      
  
      let userInfo =  await this.fetchUser(token)
      document.getElementsByClassName('image-top-div')[0].src =  userInfo.user.googlePicture
      setTimeout(() => {
            document.getElementsByClassName('loader')[0].style.display = 'none'
        }, (300));
    
    },

  async created() {
    try {
      this.posts = await PostService.getPosts()
    } catch(err){
      this.error = err.message
    }
  },
  
  methods: {
    async fetchUser(session){
      let options = {
        method: "GET",
        headers: {
            "Authorization": session,
        }
      }
      let r = await fetch(`/api/users`, options)
      switch(r.status){
        case 200:{
          let text = await r.json()
          return text;
        }
        case 401: {
          return this.$router.push('Startpage');
        }
        case 403: {
          return window.location.href='/api/auth';
        }
      }
      
    },
    async createPost() {
      this.value = false
      if(this.existingFileEdit === true) {
        PostService.editPost(this.existingId, this.title, this.selectedKind, this.dataPost, this.kindOfDataPost ,this.colorPost)  
        this.existingFileEdit = false
      } else {
        this.existingId = ''
        await PostService.insertPost(this.title, this.selectedKind, [], '#FF0000FF')
      }
      this.posts = await PostService.getPosts()
    },

    async deletePost(id) {
      PostService.deletePost(id);
      this.posts = await PostService.getPosts();
      this.dialogDelete = false
    },

    setValue(){
      this.value = true
    },

    goToChart(id){
      this.$router.push({name: 'Chart', params: {id}})
    },

    editPost(id, text, kind, data,kindOfData ,color){
      this.value = true
      this.existingFileEdit = true
      this.existingId = id
      this.title = text
      this.selectedKind = kind
      this.dataPost = data
      this.kindOfDataPost = kindOfData
      this.colorPost = color
      
    }

  }
}


</script>

<style scoped>
.post-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  
}


.post {
  outline: solid #3D4048;
  border-radius: 2%;
  background-color: #27282B;
  width: 20vw;
  height: 20vh;
  margin: 50px 80px 0 60px;
  text-align: center;
  position: relative;
}

.post-add {
  display: flex;
  justify-content: center;
  outline: dotted #3D4048;
  border-radius: 2%;
  background-color: #27282B;
  width: 20vw;
  height: 20vh;
  margin: 50px 80px 0 60px;
}

.post-add:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.body {
  width: 100%;
}

h1 {
  color: white;
}
p{
  color: white;
  opacity: 0.35;
}

.create-post-button {
  margin: 5px;
  color: white;
  background-color: green; 
  padding: 5px;
}

.image-plus{
  width: 10vw;  
}

.card-input-text-field {
  text-align: left;
}


.button-align-right{
  position: absolute !important;
  bottom:0;
  right:0;
} 

.text {
  align-self: center;
  position: static;
}

.loader {
    background: #252429;
    background: radial-gradient(#252429, #252429);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

.loader-inner {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}

.loader-line-wrap {
    animation: 
		spin 2000ms cubic-bezier(.175, .885, .32, 1.275) infinite
	;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
}
.loader-line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
.loader-line-wrap:nth-child(1) { animation-delay: -50ms; }
.loader-line-wrap:nth-child(2) { animation-delay: -100ms; }
.loader-line-wrap:nth-child(3) { animation-delay: -150ms; }
.loader-line-wrap:nth-child(4) { animation-delay: -200ms; }
.loader-line-wrap:nth-child(5) { animation-delay: -250ms; }

.loader-line-wrap:nth-child(1) .loader-line {
    border-color: hsl(0, 80%, 60%);
    height: 90px;
    width: 90px;
    top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
    border-color: hsl(60, 80%, 60%);
    height: 76px;
    width: 76px;
    top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
    border-color: hsl(120, 80%, 60%);
    height: 62px;
    width: 62px;
    top: 21px;
}
.loader-line-wrap:nth-child(4) .loader-line {
    border-color: hsl(180, 80%, 60%);
    height: 48px;
    width: 48px;
    top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
    border-color: hsl(240, 80%, 60%);
    height: 34px;
    width: 34px;
    top: 35px;
}

@keyframes spin {
    0%, 15% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
