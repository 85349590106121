<template style="height: 100vh">
  <div id="hello">
    <div id="text">
      <h2>Lets use our favorite search engine to get you <br> started for real</h2>
      <p>What we’ve gathered so far.</p>
    </div>
<!--    <ThirdPage @inputData="updateMessage"/>-->
    <div class="v-card">
        <v-card
            width="600"
            height="300"
            shaped
            color="#252429"
            elevation="24"
            class="rounded-lg"
        >
          <v-img :src="profilePic" class="ml-3" max-height="121px"  style="margin:10px 20px 0px 0;"></v-img>
          <v-divider
          vertical
          style="background-color:grey"
          >
          </v-divider>
          <v-card-text class="text-left">
            <span>
              Name: <b>{{ this.$store.state.items.nameFull }}</b>
              <br>
              <br>
              Birthday: <b>{{this.$store.state.items.birthday}}</b>
              <br>
              <br>
              Role: <b>{{this.$store.state.items.role}}</b>
              <br>
              <br>
              E-mail: <b>{{emailStrip()}}</b>
            </span>
          </v-card-text>
          <v-card-text class="bottom_right">
              <p>created on: {{ currentDate()}}</p>
              
          </v-card-text>
        </v-card>
    </div>


    <div id="balk" class="balkDiv">
      <img :src="balk100" alt="attribute" class="balk"/>
      <img :src="balk100" alt="attribute" class="balk"/> 
      <img :src="balk" alt="attribute" class="balk"/>
    </div>

    <div id="buttonclass">
      <button v-on:click="openOauth">Continue your journey</button>
    </div>

  </div>
</template>

<script>
// import image from '/static/database.svg'
import balk from '/static/balken/balk.svg'
import balk100 from '/static/balken/balk100.svg'
import profilePic from '/static/blankProfile.png'
// import ThirdPage from "./ThirdPage";


export default {
  // components: {ThirdPage},
  setup: {

  },
  data: () => ({
      balk: balk,
      balk100: balk100,
      profilePic: profilePic,
      nameFull: null,
      role: null,
      birthDate: 'test',
      email: '',
  }),
  methods: {
    openOauth(){
      window.location.href='/api/auth';
    },
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      return date;
    },
    emailStrip(){
      if(this.$store.state.items.email.length > 26){
        this.email = this.$store.state.items.email.substr(0,23) + "..."
        return this.email
      } else{
        this.email = this.$store.state.items.email
        return this.email
      }
    }
  },
}


</script>

<style scoped>
.bottom_right {
  text-align: right;
  position: absolute;
  bottom:0;
}


.v-card{
  margin: 10px 0 1.5vw  0;
  display: flex;
  justify-content: center;
}
span {
  font-size: 1.5em;
  color: white;
}

span b {
  color: grey;
  font-size: 80%;
  font-weight: normal;
}

h2 {
  font-size: 1.7vw;
  margin-top: 4vw;
  color: white;
  margin-bottom: 1vw;
}

button{
 font-size: 1.5em;
  padding:0.5vw 7vw;
  border-radius: 0.2em;
  background-color: #5E6AD2;
  display: inline-block;
  text-align: center;
  margin: 2em 2px;
  color: white;
  cursor: pointer;
  box-shadow: 0 12px 20px 0 rgba(0,0,0,0.2), 0 12px 30px 0 rgba(0,0,0,0.19);
}

p {
  margin-top: 0;
  color: #454e5c;
  font-size: 1.3em;
}

</style>
