import Vue from 'vue'
import App from './App';
import vuetify from "./plugins/vuetify";
import VueRouter from 'vue-router';
import store from "./store/store";
import VueCookies from 'vue-cookies';
import 'animate.css';
import StartPage from './components/loginComponents/StartPage';
import SecondPage from './components/loginComponents/SecondPage';
import ThirdPage from './components/loginComponents/ThirdPage';
import FourthPage from "./components/loginComponents/FourthPage";
import MainPage from "./components/mainPage.vue"
import UserPage from "./components/manageViews/users.vue"
import AnalyticsPage from "./components/manageViews/analytics.vue"
import StoragePage from "./components/manageViews/storage.vue"
import SettingsPage from "./components/maintanceViews/settings.vue"
import ChartPage from "./components/chart.vue"
import "@/assets/css/main.css"

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueCookies);


const routes= [
  {
    path: '/',
    name: 'Startpage',
    component: StartPage
  },
  {
    path: '/secondpage',
    name: 'Secondpage',
    component: SecondPage
  },
  {
    path: '/thirdpage',
    name: 'Thirdpage',
    component: ThirdPage
  },
  {
    path: '/fourthpage',
    name: 'Fourthpage',
    component: FourthPage
  },
  {
    path: '/mainpage',
    name: 'Mainpage',
    component: MainPage
  },
  {
    path: '/userpage',
    name: 'Users',
    component: UserPage
  },
  {
    path: '/analyticspage',
    name: 'Analytics',
    component: AnalyticsPage
  },
  {
    path: '/storagepage',
    name: 'Storage',
    component: StoragePage
  },
  {
    path: '/settingspage',
    name: 'Settings',
    component: SettingsPage
  },
  {
    path: '/chart/:id',
    name: 'Chart',
    component: ChartPage
  },

];

const router = new VueRouter({
  routes
});


new Vue({
  el: '#app',
    router,
    vuetify,
    store,
    render: h => h(App),
    created() {
      this.$store.commit('save')
    }
});




