<template>
    <div class="unniversal-class-flex">
        <side-bar></side-bar>
        <h1>AnalyticsPage</h1>
    </div>
</template>

<script>
import sideBar from '@/components/mainPageComponents/sideBar.vue'

export default {
  components: { sideBar },
  data(){
    return {
        
    }
  },
}


</script>

<style scoped>


</style>
