<template>
  <div id="hello">
    <h1>Say Hello!</h1>
    <div class="wave" id="image">
      <img :src="hello"  class='helloImg' alt="hello">
    </div>

    <div id="balk" class="balkDiv">
      <img :src="balk100" alt="attribute" class="balk"/>
      <img :src="balk" alt="attribute" class="balk"/>
      <img :src="balk" alt="attribute" class="balk"/>
    </div>
  </div>
</template>

<script>
import hello from '/static/hello.svg'
import balk from '/static/balken/balk.svg'
import balk100 from '/static/balken/balk100.svg'

export default {
  name: 'App',
  data() {
    return {
      hello: hello,
      balk: balk,
      balk100: balk100,
      timeout: null
    }
  },
  created() {
    let local = this;
    setTimeout(function() {
       if(local.$route.name == 'Secondpage'){
         local.$router.push({ path: '/thirdpage'}), 2200
       }
      }, 2000);
  }

}
</script>

<style scoped>
h1 {
  font-size: 3.3vw;
  margin-top: 3%;
  color: white;
  margin-bottom: 100px;
}

.helloImg {
  padding: 0 0 6.1vw 0;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(18.0deg) }
  20% { transform: rotate(-12.0deg) }
  30% { transform: rotate(18.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
</style>
