<template>
  <v-app>
    <transition
        name="fade"
        mode="out-in"
      >
      <router-view/>
      </transition>
  </v-app>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
#app {
  text-align: center;
  margin: auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  background: #252429;
  display: flex;
}
</style>
