import { render, staticRenderFns } from "./ThirdPage.vue?vue&type=template&id=4fa8929c&scoped=true&style=max-height%3A%20100vh&"
import script from "./ThirdPage.vue?vue&type=script&lang=js&"
export * from "./ThirdPage.vue?vue&type=script&lang=js&"
import style0 from "./ThirdPage.vue?vue&type=style&index=0&id=4fa8929c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa8929c",
  null
  
)

export default component.exports