import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        items: [{name: '', birthday: '', role: '', email: ''}],
    },
    mutations: {
        save (state) {
            state.items = JSON.parse(localStorage.getItem("info") || "[]");
        },
    }
});